import React from 'react';
import { ReferenceInput, SelectInput, SimpleForm, TextInput, minLength } from 'react-admin';

const UserForm = ({ isNew, ...props }) => {
  return (
    <SimpleForm>
      <TextInput source='name' label='Name' fullWidth />
      <TextInput source='username' label='Username' fullWidth required validate={minLength(3)} />
      <TextInput source='email' label='Email' fullWidth required />
      <TextInput
        type='password'
        source='password'
        label='Password'
        fullWidth
        required={isNew}
        validate={minLength(6)}
      />
      <ReferenceInput source='role' reference='users-permissions/roles' label='Role'>
        <SelectInput optionText='name' required />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default UserForm;
