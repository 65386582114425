export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const API_DATA_URL = `${API_BASE_URL}/api`;
export const API_AUTH_URL = `${API_BASE_URL}/api/auth/local`;

export const API_DEBUG = process.env.REACT_APP_API_DEBUG;

export const STORAGE_KEY_AUTH_TOKEN = `decoplant-admin-token`;
export const AUTH_USER_ALLOWED_ROLE = 'Admin';

export const FILE_FORMATS_ACCEPTED = {
  IMAGES: ['image/jpeg', 'image/png'],
  PDF: 'application/pdf',
  IMAGES_ANY: 'image/*',
  VIDEOS_ANY: 'video/*',
  VIDEOS: [], // Not specified yet
  AUDIOS: [], // Not specified yet, if any
  DOCUMENTS: [], // Not specified yet
  OTHER: [], // Not specified yet, if any
};
