import React from 'react';
import { Edit } from 'react-admin';

import RangeForm from '../Common/RangeForm';

const RangesEdit = (props) => (
  <Edit {...props} mutationMode='pessimistic'>
    <RangeForm />
  </Edit>
);

export default RangesEdit;
