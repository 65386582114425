import React from 'react';
import { ArrayInput, SimpleForm, SimpleFormIterator, TextInput, required } from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

import LimitedRichTextInput from '../../../common/Inputs/LimitedRichTextInput';
import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';
import StrapiPdfFileInput from '../../../common/Inputs/StrapiPdfFileInput';

const AcademyForm = ({ defaultValues }) => {
  return (
    <SimpleForm defaultValues={defaultValues}>
      <TextInput source='title' validate={required()} fullWidth />
      <LimitedRichTextInput source='header' validate={required()} multiline fullWidth />
      <LimitedRichTextInput source='description' validate={required()} multiline fullWidth />
      <ColorInput source='text_color' validate={required()} />
      <ColorInput source='color' label='Accent color' validate={required()} />
      <TextInput source='order' type='number' validate={required()} fullWidth />
      <StrapiImageFileInput
        source='background'
        label='Background'
        placeholder={<p>{'Drop your background image here'}</p>}
      />
      <StrapiPdfFileInput source='pdf' label='Pdf' />
      <ArrayInput source='academy_pages'>
        <SimpleFormIterator getItemLabel={(index) => `Page ${index + 1}`}>
          <TextInput source='header' validate={required()} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export default AcademyForm;
