import React, { useMemo } from 'react';
import { Create } from 'react-admin';

import SustainabilityForm from '../Common/SustainabilityForm';

const SustainabilitiesCreate = (props) => {
  const defaultValues = useMemo(() => {
    // Without default value, has a display bug
    return {
      color: '#000000',
      text_color: '#ffffff',
    };
  }, []);

  return (
    <Create {...props}>
      <SustainabilityForm defaultValues={defaultValues} />
    </Create>
  );
};

export default SustainabilitiesCreate;
