import { INTENDED_ROLES } from './customizedDataProviderConfig';

export const filterRoles = (roles = []) => {
  return roles.filter((role) => INTENDED_ROLES.includes(role.name));
};

export const flattenRelations = (item) => {
  if (item) {
    if (Array.isArray(item)) {
      return item.map((entry) => flattenRelations(entry));
    }

    if (typeof item === 'object') {
      const keys = Object.keys(item);
      if (keys[0] === 'id' && keys.length === 1) {
        return item.id;
      }

      const newItem = {};

      for (let key of keys) {
        newItem[key] = flattenRelations(item[key]);
      }

      return newItem;
    }
  }

  return item;
};
