import React, { useState } from 'react';
import { FormDataConsumer, SelectInput, SimpleForm, TextInput, required } from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

import LimitedRichTextInput from '../../../common/Inputs/LimitedRichTextInput';
import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';
import ConditionalFormSettings from './ConditionalFormSettings';

const CHOICES = {
  SYSTEM_NAME: [
    { id: 'main_menu', name: 'Main Menu' },
    { id: 'ranges', name: 'Ranges' },
    { id: 'case_categories', name: 'Case Categories' },
    { id: 'academies', name: 'Academies' },
    { id: 'sustainability', name: 'Sustainability' },
  ],
};

const MenuPageForm = ({ defaultValues }) => {
  const [disableButtonFields, setDisableButtonFields] = useState();
  const [systemNameDisabled, setSystemNameDisabled] = useState();

  return (
    <SimpleForm defaultValues={defaultValues}>
      <FormDataConsumer>
        {({ formData }) => (
          <ConditionalFormSettings
            formData={formData}
            setDisableButtonFields={setDisableButtonFields}
            setSystemNameDisabled={setSystemNameDisabled}
          />
        )}
      </FormDataConsumer>

      <SelectInput
        source='system_name'
        label='System Name'
        validate={required()}
        disabled={systemNameDisabled}
        choices={CHOICES.SYSTEM_NAME}
        fullWidth
      />
      <TextInput
        source='button_title'
        validate={disableButtonFields ? undefined : required()}
        disabled={disableButtonFields}
        fullWidth
      />
      <TextInput
        source='button_subtitle'
        validate={disableButtonFields ? undefined : required()}
        disabled={disableButtonFields}
        fullWidth
      />
      <StrapiImageFileInput
        source='button_image'
        label='Button Image'
        disabled={disableButtonFields}
        placeholder={<p>{'Drop your button image here'}</p>}
      />
      <LimitedRichTextInput source='header' multiline fullWidth />
      <LimitedRichTextInput source='body' multiline fullWidth />
      <ColorInput source='text_color' validate={required()} />
      {/* - We don't intend to reorder the main menu buttons yet
        <TextInput source='order' type='number' fullWidth />
      */}
      <StrapiImageFileInput
        source='background'
        label='Background'
        placeholder={<p>{'Drop your background image here'}</p>}
      />
    </SimpleForm>
  );
};

export default MenuPageForm;
