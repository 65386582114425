import React from 'react';
import { FunctionField } from 'react-admin';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';

const HasSingleRelatedItemField = ({ label, sortable, sortBy, fieldName }) => {
  return (
    <FunctionField
      label={label}
      sortable={sortable}
      sortBy={sortBy}
      render={(record) => {
        if (record[fieldName]) {
          return <Check title='Yes' />; // '✔';
        } else {
          return <Close />; //'✖';
        }
      }}
    />
  );
};

export default HasSingleRelatedItemField;
