import React, { useCallback, useMemo, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

import LimitedRichTextInput from '../../../common/Inputs/LimitedRichTextInput';
import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';
import { getApiFilterForReferencedInput } from '../../../common/Inputs/utils';

const CategoryForm = ({ defaultValues }) => {
  const [formTabACFilter, setFormTabACFilter] = useState({});
  const { products, ranges } = formTabACFilter;

  const productsFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(products, 'name');
  }, [products]);

  const rangesFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(ranges, 'title');
  }, [ranges]);

  const setProductsACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        products: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  const setRangesACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        ranges: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  return (
    <SimpleForm defaultValues={defaultValues}>
      <TextInput source='title' validate={required()} fullWidth />
      <LimitedRichTextInput source='header' multiline fullWidth />
      <LimitedRichTextInput source='description' multiline fullWidth />
      <TextInput source='order' type='number' fullWidth />
      <ColorInput source='color' label='Accent color' validate={required()} />
      <ReferenceInput reference='ranges' source='range' filter={rangesFilterUsed} fullWidth>
        <AutocompleteInput optionText='title' fullWidth setFilter={setRangesACFilter} />
      </ReferenceInput>
      <ReferenceArrayInput
        reference='products'
        source='products'
        filter={productsFilterUsed}
        fullWidth
      >
        <AutocompleteArrayInput optionText='name' fullWidth setFilter={setProductsACFilter} />
      </ReferenceArrayInput>
      <StrapiImageFileInput
        source='background'
        label='Background'
        placeholder={<p>{'Drop your background image here'}</p>}
      />
    </SimpleForm>
  );
};

export default CategoryForm;
