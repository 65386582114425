import React from 'react';
import { Edit } from 'react-admin';

import SustainabilityForm from '../Common/SustainabilityForm';

const SustainabilitiesEdit = (props) => (
  <Edit {...props} mutationMode='pessimistic'>
    <SustainabilityForm />
  </Edit>
);

export default SustainabilitiesEdit;
