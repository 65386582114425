import React, { useMemo } from 'react';
import { Create } from 'react-admin';

import CategoryForm from '../Common/CategoryForm';

const CategoriesCreate = (props) => {
  const defaultValues = useMemo(() => {
    // Without default value, has a display bug
    return {
      color: '#000000',
    };
  }, []);

  return (
    <Create {...props}>
      <CategoryForm defaultValues={defaultValues} />
    </Create>
  );
};

export default CategoriesCreate;
