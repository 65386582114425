import React from 'react';
import { Edit } from 'react-admin';

import SeriesEntryForm from '../Common/SeriesEntryForm';

const SeriesEntriesEdit = (props) => (
  <Edit {...props} mutationMode='pessimistic'>
    <SeriesEntryForm />
  </Edit>
);

export default SeriesEntriesEdit;
