import React from 'react';
import { Edit } from 'react-admin';
import ProductForm from '../Common/ProductForm';

const ProductsEdit = (props) => {
  return (
    <Edit {...props} mutationMode='pessimistic'>
      <ProductForm />
    </Edit>
  );
};

export default ProductsEdit;
