import React, { useMemo } from 'react';
import { NumberInput, minValue, maxValue } from 'react-admin';

const defaultValidators = [
  minValue(0, 'Cannot set less than 0% (out of screen boundary)'),
  maxValue(100, 'Cannot set more than 100% (out of screen boundary)'),
];

const parseValue = (value) => {
  return value ? `${value}` : undefined;
};

const formatValue = (value) => {
  return value ? Number.parseFloat(value) : undefined;
};

// To be used in place of NumberInput, when applicable
const CssPercentageInput = ({ min, max, step, parse, format, validate, ...restProps }) => {
  const validateUsed = useMemo(() => {
    const validateUsed = [...defaultValidators];

    if (validate) {
      if (Array.isArray(validate)) {
        validateUsed.push(...validate);
      } else {
        validateUsed.push(validate);
      }
    }

    return validateUsed;
  }, [validate]);

  return (
    <NumberInput
      min={0}
      max={100.0}
      step={0.1}
      format={formatValue}
      parse={parseValue}
      validate={validateUsed}
      {...restProps}
    />
  );
};

CssPercentageInput.prototype = NumberInput; // For usage in SimpleFormIterator

export default CssPercentageInput;

/*const parseValue = (value) => {
  return value ? `${value}%` : undefined;
};

const formatValue = (value) => {
  return value ? Number.parseFloat(value.replace('%', '')) : undefined;
}; */
