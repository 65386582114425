import React from 'react';
import { Edit } from 'react-admin';

import AcademyForm from '../Common/AcademyForm';

const AcademiesEdit = (props) => (
  <Edit {...props} mutationMode='pessimistic'>
    <AcademyForm />
  </Edit>
);

export default AcademiesEdit;
