import { API_AUTH_URL, STORAGE_KEY_AUTH_TOKEN, AUTH_USER_ALLOWED_ROLE } from '../utils/constants';

const authProvider = {
  login: async ({ username, password }) => {
    const authUrl = API_AUTH_URL;
    const request = new Request(authUrl, {
      method: 'POST',
      body: JSON.stringify({ identifier: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    const response = await fetch(request);

    const body = await response.json();

    if (response.status < 200 || response.status >= 300) {
      throw new Error(`${response.statusText}${body.error && ` - ${body.error.message}`}`);
    }

    if (body) {
      if (body.user && body.user.role && body.user.role.name === AUTH_USER_ALLOWED_ROLE) {
        localStorage.setItem(STORAGE_KEY_AUTH_TOKEN, JSON.stringify(body));
        return;
      }
    }

    throw new Error(`This user doesn't have access to the Admin application`);
  },
  logout: () => {
    localStorage.removeItem(STORAGE_KEY_AUTH_TOKEN);
    return Promise.resolve();
  },
  checkAuth: (params) => {
    return localStorage.getItem(STORAGE_KEY_AUTH_TOKEN) ? Promise.resolve() : Promise.reject();
  },
  checkError: (error) => {
    if (error.status && error.status === 401) {
      return Promise.reject('Credentials expired.');
    }
    return Promise.resolve();
  },
  getPermissions: (permissions) => Promise.resolve(),
};

export default authProvider;
