import React, { useCallback, useMemo, useState } from 'react';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';
import { getApiFilterForReferencedInput } from '../../../common/Inputs/utils';

const SeriesEntryForm = () => {
  const [formTabACFilter, setFormTabACFilter] = useState({});
  const { case_entries, products } = formTabACFilter;

  const caseEntriesFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(case_entries, 'name');
  }, [case_entries]);

  const productsFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(products, 'name');
  }, [products]);

  const setCaseEntriesACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        case_entries: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  const setProductsACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        products: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  return (
    <SimpleForm>
      <TextInput source='title' validate={required()} fullWidth />
      <StrapiImageFileInput
        source='logo'
        label='Logo'
        placeholder={<p>{'Drop your logo here'}</p>}
      />
      <ReferenceArrayInput
        reference='products'
        source='products'
        filter={productsFilterUsed}
        fullWidth
      >
        <AutocompleteArrayInput optionText='name' fullWidth setFilter={setProductsACFilter} />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        reference='case-entries'
        source='case_entries'
        filter={caseEntriesFilterUsed}
        fullWidth
      >
        <AutocompleteArrayInput
          label='Cases'
          optionText='name'
          fullWidth
          setFilter={setCaseEntriesACFilter}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

export default SeriesEntryForm;
