import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useRecordContext, useTranslate } from 'ra-core';

import { sanitizeFieldRestProps } from 'ra-ui-materialui';
/*import {
  PublicFieldProps,
  InjectedFieldProps,
  fieldPropTypes,
} from 'ra-ui-materialui/src/field/types';
import { SxProps } from '@mui/system'; */
import { Link } from '@mui/material';

import { getDataFromExistingStrapiFileObject } from './utils';

export const StrapiSingleFileLinkItem = (props) => {
  const { itemData, target, download, ping, rel, referrerPolicy } = props;

  const { name, url } = useMemo(() => {
    if (itemData.id) {
      return getDataFromExistingStrapiFileObject(itemData);
    }

    if (itemData.rawFile) {
      return {
        name: itemData.title,
        url: itemData.src,
      };
    }

    return {};
  }, [itemData]);

  return (
    <Link
      href={url}
      title={name}
      target={target}
      download={download}
      ping={ping}
      rel={rel}
      referrerPolicy={referrerPolicy}
      variant='body2'
    >
      {name}
    </Link>
  );
};

export const StrapiFileField = (props) => {
  const {
    className,
    emptyText,
    // source,
    title,
    src,
    target,
    download,
    ping,
    rel,
    referrerPolicy,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const translate = useTranslate();

  const sourceValue = record;

  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component='span'
        variant='body2'
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText && translate(emptyText, { _: emptyText })}
      </Typography>
    ) : (
      <Root className={className} {...sanitizeFieldRestProps(rest)} />
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <StyledList className={className} {...sanitizeFieldRestProps(rest)}>
        {sourceValue.map((file, index) => {
          return (
            <li key={index}>
              <StrapiSingleFileLinkItem
                itemData={file}
                target={target}
                download={download}
                ping={ping}
                rel={rel}
                referrerPolicy={referrerPolicy}
              />
            </li>
          );
        })}
      </StyledList>
    );
  }

  return (
    <Root className={className} {...sanitizeFieldRestProps(rest)}>
      <StrapiSingleFileLinkItem
        itemData={sourceValue}
        target={target}
        download={download}
        ping={ping}
        rel={rel}
      />
    </Root>
  );
};

const PREFIX = 'RaFileField';

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  display: 'inline-block',
});

const StyledList = styled('ul')({
  display: 'inline-block',
});
