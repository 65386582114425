import React from 'react';
import { SelectInput, SimpleForm, TextInput, required } from 'react-admin';

import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';

export const TYPE_CHOICES = [
  {
    id: 'dimension',
    name: 'Dimension',
  },
  {
    id: 'lighting',
    name: 'Lighting',
  },
  {
    id: 'watering',
    name: 'Watering',
  },
  {
    id: 'other',
    name: 'Other',
  },
];

const ProductIconForm = ({ defaultValues }) => {
  return (
    <SimpleForm defaultValues={defaultValues}>
      <TextInput source='title' validate={required()} fullWidth />
      <SelectInput source='type' choices={TYPE_CHOICES} validate={required()} fullWidth />
      <StrapiImageFileInput
        source='image'
        label='Image'
        placeholder={<p>{'Drop your image here'}</p>}
      />
    </SimpleForm>
  );
};

export default ProductIconForm;
