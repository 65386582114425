import qs from 'qs';
import { stringify } from 'query-string';

import { API_DEBUG } from '../utils/constants';
import { MULTIPART_FIELDS } from './baseStrapiDataProviderConfig';
import {
  getStrapiFiltersObject,
  handleFileUpload,
  unwrapReceivedCollection,
  unwrapReceivedItem,
} from './baseStrapiDataProviderUtils';
import { ERROR_MISSING_HEADER_X_TOTAL_COUNT, httpClient } from './dataProviderUtils';

export const STRAPI_REQUESTS = {
  getList: async (apiUrl, resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const { findMode, ...restOfFilter } = params.filter;

    const filters = getStrapiFiltersObject(resource, restOfFilter) || {};

    const queryObj = {
      pagination: {
        start: (page - 1) * perPage,
        limit: perPage,
      },
      sort: [`${field}:${order}`],
      filters,
    };

    if (findMode) {
      queryObj.findMode = findMode;
    }

    const query = qs.stringify(queryObj, { encodeValuesOnly: true });

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url);
  },
  getMany: async (apiUrl, resource, params) => {
    const query = qs.stringify(
      {
        filters: getStrapiFiltersObject(resource, {
          id: ['$in', params.ids],
        }),
      },
      { encodeValuesOnly: true }
    );

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url);
  },
};

const baseStrapiDataProvider = (apiUrl) => ({
  getList: async (resource, params) => {
    try {
      const { headers, json } = await STRAPI_REQUESTS.getList(apiUrl, resource, params);

      if (!headers.has('x-total-count')) {
        throw new Error(ERROR_MISSING_HEADER_X_TOTAL_COUNT);
      }

      const data = unwrapReceivedCollection(json.data);

      return {
        data: data,
        total: parseInt(headers.get('x-total-count').split('/').pop(), 10), // TODO
      };
    } catch (err) {
      API_DEBUG && console.log(err.stack);
      throw err;
    }
  },

  getOne: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`);

    return { data: unwrapReceivedItem(json.data) };
  },

  getMany: async (resource, params) => {
    const { json } = await STRAPI_REQUESTS.getMany(apiUrl, resource, params);

    return { data: unwrapReceivedCollection(json?.data) };
  },

  // ?findMode=tuple
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = qs.stringify({
      pagination: {
        start: (page - 1) * perPage,
        limit: perPage,
      },
      sort: [`${field}:${order}`],
    });

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(ERROR_MISSING_HEADER_X_TOTAL_COUNT);
      }

      const data = json?.data?.map((entity) => {
        const flatEntity = entity.attributes;
        flatEntity.id = entity.id;
        return flatEntity;
      });

      return {
        data: data,
        total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
      };
    });
  },

  update: (resource, params) => {
    const fieldNames = Object.keys(params.data);

    // handle file upload
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      if (MULTIPART_FIELDS[resource]?.[fieldName]) {
        const formData = handleFileUpload(resource, params.data);

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PUT',
          body: formData,
        }).then(({ json }) => {
          const data = json?.data?.attributes || {};
          data.id = json?.data?.id;

          return { data: data };
        });
      }
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify({ data: params.data }),
    }).then(({ json }) => {
      const data = json?.data?.attributes || {};
      data.id = json?.data?.id;

      return { data: data };
    });
  },

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify({ data: params.data }),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json?.data?.id) })),

  create: (resource, params) => {
    const fieldNames = Object.keys(params.data);

    // handle file upload
    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      if (MULTIPART_FIELDS[resource]?.[fieldName]) {
        const formData = handleFileUpload(resource, params.data);

        return httpClient(`${apiUrl}/${resource}`, {
          method: 'POST',
          body: formData,
        }).then(({ json }) => {
          const data = json?.data?.attributes || {};
          data.id = json?.data?.id;

          return { data: data };
        });
      }
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify({ data: params.data }),
    }).then(({ json }) => ({
      data: { ...params.data, id: json?.data?.id },
    }));
  },

  delete: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    });

    return { data: unwrapReceivedItem(json.data) };
  },

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json?.data?.id) })),
});

export default baseStrapiDataProvider;
