import React from 'react';
import { Create } from 'react-admin';

import SeriesEntryForm from '../Common/SeriesEntryForm';

const SeriesEntriesCreate = (props) => (
  <Create {...props}>
    <SeriesEntryForm />
  </Create>
);

export default SeriesEntriesCreate;
