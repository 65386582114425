import React, { Fragment } from 'react';
import { FunctionField } from 'react-admin';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';

const HasMultipleRelatedItemField = ({ label, sortable, sortBy, fieldName }) => {
  return (
    <FunctionField
      label={label}
      sortable={sortable}
      sortBy={sortBy}
      render={(record) => {
        if (record[fieldName] && record[fieldName].length) {
          return (
            <Fragment>
              <Check title='Yes' />
              {` ${record[fieldName].length}`}
            </Fragment>
          ); // '✔';
        } else {
          return <Close />; //'✖';
        }
      }}
    />
  );
};

export default HasMultipleRelatedItemField;
