import { API_BASE_URL } from '../../../utils/constants';

const getStrapiUploadUrl = (url) => `${API_BASE_URL}${url}`;

export const STRAPI_IMAGE_SIZES = ['thumbnail', 'small', 'medium', 'large'];

export const getDataFromExistingStrapiImageObject = (itemData, preferredSize) => {
  const { formats, name, url } = itemData;
  let title = name || '';
  let alt = title;
  let src = url ? getStrapiUploadUrl(url) : '';

  if (itemData.formats && preferredSize) {
    for (let size of STRAPI_IMAGE_SIZES) {
      const format = formats[size];

      const { name, url } = format || {};

      if (name && url) {
        title = name;
        alt = title;
        src = getStrapiUploadUrl(url);
      }

      if (size === preferredSize) {
        break;
      }
    }
  }

  return { alt, src, title };
};

export const getDataFromExistingStrapiFileObject = (itemData) => {
  const { name, url } = itemData || {};

  return {
    name,
    url: url ? getStrapiUploadUrl(url) : '',
  };
};
