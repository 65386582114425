import React from 'react';
import { FormTab, TabbedForm } from 'react-admin';

import CaseEntryMainTabContent from './CaseEntryMainTabContent';
import PlantsTabContent from './PlantsTabContent';
import ServiceTabContent from './ServiceTabContent';
import TestimonialsTabContent from './TestimonialsTabContent';

const CaseEntryForm = ({ defaultValues }) => {
  return (
    <TabbedForm defaultValues={defaultValues}>
      <FormTab label='Case'>
        <CaseEntryMainTabContent />
      </FormTab>
      <FormTab label={'Plants'} path='plants'>
        <PlantsTabContent />
      </FormTab>
      <FormTab label={'Service'} path='service'>
        <ServiceTabContent />
      </FormTab>
      <FormTab label={'Customers say'} path='customers-say'>
        <TestimonialsTabContent />
      </FormTab>
    </TabbedForm>
  );
};

export default CaseEntryForm;
