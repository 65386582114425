export const fileInputStyle = {
  '& .RaFileInput-removeButton': {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },

  // '& .RaFileInput-root .previews': {},
};

export const imageFieldStyle = {
  '& .RaImageField-image': {
    maxWidth: '100%',
    maxHeight: '200px',
  },
};

export const singleFieldListStyle = {
  '& > a': {
    marginRight: '8px',
  },
};

export const columnCreatedAtStyle = {
  '& .column-createdAt': {
    textAlign: 'right',
  },
};
