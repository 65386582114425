import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import customizedDataProvider from './providers/customizedDataProvider';
import authProvider from './providers/authProvider';
import { AcademiesList, AcademiesCreate, AcademiesEdit } from './render/views/academies';
import {
  AcademyPagesList,
  AcademyPagesCreate,
  AcademyPagesEdit,
} from './render/views/academy-pages';
import {
  CaseCategoriesList,
  CaseCategoriesCreate,
  CaseCategoriesEdit,
} from './render/views/case-categories';
import { CaseEntriesList, CaseEntriesCreate, CaseEntriesEdit } from './render/views/case-entries';
import { CategoriesList, CategoriesCreate, CategoriesEdit } from './render/views/categories';
import {
  ProductIconsList,
  ProductIconsCreate,
  ProductIconsEdit,
} from './render/views/product-icons';
import { ProductsList, ProductsCreate, ProductsEdit } from './render/views/products';
import { RangesList, RangesCreate, RangesEdit } from './render/views/ranges';
import {
  SeriesEntriesList,
  SeriesEntriesCreate,
  SeriesEntriesEdit,
} from './render/views/series-entries';
import {
  SustainabilitiesList,
  SustainabilitiesCreate,
  SustainabilitiesEdit,
} from './render/views/sustainabilities';
import { MenuPagesList, MenuPagesCreate, MenuPagesEdit } from './render/views/menu-pages';
/*import {
  TranslationsList,
  TranslationsCreate,
  TranslationsEdit,
} from './render/views/translations';*/
import { UsersList, UsersCreate, UsersEdit } from './render/views/users';
import { API_DATA_URL } from './utils/constants';

const App = () => (
  <Admin dataProvider={customizedDataProvider(API_DATA_URL)} authProvider={authProvider}>
    <Resource
      name='menu-pages'
      list={MenuPagesList}
      create={MenuPagesCreate}
      edit={MenuPagesEdit}
    />
    <Resource name='ranges' list={RangesList} create={RangesCreate} edit={RangesEdit} />
    <Resource
      name='categories'
      list={CategoriesList}
      create={CategoriesCreate}
      edit={CategoriesEdit}
    />
    <Resource name='products' list={ProductsList} create={ProductsCreate} edit={ProductsEdit} />
    <Resource
      name='product-icons'
      list={ProductIconsList}
      create={ProductIconsCreate}
      edit={ProductIconsEdit}
      options={{ label: 'Product Icons' }}
    />
    <Resource
      name='series-entries'
      list={SeriesEntriesList}
      create={SeriesEntriesCreate}
      edit={SeriesEntriesEdit}
      options={{ label: 'Series' }}
    />
    <Resource
      name='case-categories'
      list={CaseCategoriesList}
      create={CaseCategoriesCreate}
      edit={CaseCategoriesEdit}
      options={{ label: 'Case Categories' }}
    />
    <Resource
      name='case-entries'
      list={CaseEntriesList}
      create={CaseEntriesCreate}
      edit={CaseEntriesEdit}
      options={{ label: 'Cases' }}
    />
    <Resource name='academies' list={AcademiesList} create={AcademiesCreate} edit={AcademiesEdit} />
    <Resource
      name='academy-pages'
      list={AcademyPagesList}
      create={AcademyPagesCreate}
      edit={AcademyPagesEdit}
      options={{ label: 'Academy Pages' }}
    />
    <Resource
      name='sustainabilities'
      list={SustainabilitiesList}
      create={SustainabilitiesCreate}
      edit={SustainabilitiesEdit}
      options={{ label: 'Sustainability Pages' }}
    />
    {/* Temporarily hiding, as currently no Intl handled on frontend
    <Resource
      name='translations'
      list={TranslationsList}
      create={TranslationsCreate}
      edit={TranslationsEdit}
    /> */}
    <Resource
      name='users'
      list={UsersList}
      create={UsersCreate}
      edit={UsersEdit}
      options={{ label: 'Users' }}
    />
  </Admin>
);

export default App;
