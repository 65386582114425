import React, { Fragment, useCallback, useMemo, useState } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
  required,
} from 'react-admin';

import KeyValueArrayInput from '../../../common/Inputs/KeyValueArrayInput';
import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';
import { getApiFilterForReferencedInput } from '../../../common/Inputs/utils';
import { OPTION_TEXT } from '../../../common/optionRenders';

const ProductMainTabContent = () => {
  const [formTabACFilter, setFormTabACFilter] = useState({});
  const { categories, series_entries } = formTabACFilter;

  const categoriesFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(categories, 'title');
  }, [categories]);

  const productIconsFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput();
  }, []);

  const seriesEntriesFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(series_entries, 'title');
  }, [series_entries]);

  const setCategoriesACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        categories: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  const setSeriesEntriesACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        series_entries: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  return (
    <Fragment>
      <TextInput source='name' label='Product Name' validate={required()} fullWidth />
      <BooleanInput source='active' />
      <TextInput source='art_nr' label='Item Number' validate={required()} fullWidth />
      <ReferenceArrayInput
        reference='series-entries'
        source='series_entries'
        filter={seriesEntriesFilterUsed}
        fullWidth
      >
        <AutocompleteArrayInput
          optionText='title'
          label='Series'
          fullWidth
          setFilter={setSeriesEntriesACFilter}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        reference='product-icons'
        source='icons'
        filter={productIconsFilterUsed}
        fullWidth
      >
        <SelectArrayInput optionText='title' label='Icons' fullWidth />
      </ReferenceArrayInput>
      <StrapiImageFileInput
        source='images'
        label='Images'
        placeholder={<p>{'Drop your images here'}</p>}
        multiple
      />
      <KeyValueArrayInput source='info' labelPrefix='Product Info' />
      <TextInput source='description' validate={required()} multiline={true} fullWidth />
      <ReferenceArrayInput
        reference='categories'
        source='categories'
        filter={categoriesFilterUsed}
        fullWidth
      >
        <AutocompleteArrayInput
          optionText={OPTION_TEXT.categories}
          fullWidth
          setFilter={setCategoriesACFilter}
        />
      </ReferenceArrayInput>
    </Fragment>
  );
};

export default ProductMainTabContent;
