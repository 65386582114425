export const OPTION_TEXT = {
  categories: (category = {}) => {
    if (category == null) {
      return '';
    }
    const { title = '', range } = category;
    const { title: rangeTitle } = range || { title: '?' };

    return `${title} (of ${rangeTitle})`;
  },
};
