import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';

import HasMultipleRelatedItemField from '../../../common/Fields/HasMultipleRelatedItemField';
import { columnCreatedAtStyle, singleFieldListStyle } from '../../../common/styles';

const CaseEntriesList = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick='edit' sx={columnCreatedAtStyle}>
        <TextField source='name' />
        <TextField source='art_nr' label='Item No.' />
        <HasMultipleRelatedItemField label='Images' sortBy='images' fieldName={'images'} />
        <TextField source='description' />
        <ReferenceArrayField
          source='case_categories'
          reference='case-categories'
          label='Categories'
        >
          <SingleFieldList sx={singleFieldListStyle}>
            <TextField source='title' />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField source='series_entries' reference='series-entries' label='Series'>
          <SingleFieldList sx={singleFieldListStyle}>
            <TextField source='title' />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source='createdAt' />
      </Datagrid>
    </List>
  );
};

export default CaseEntriesList;
