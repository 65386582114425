import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const ConditionalFormSettings = ({ formData, setDisableButtonFields, setSystemNameDisabled }) => {
  const formContext = useFormContext();
  const { setValue } = formContext;

  const changeFormSettings = useCallback(() => {
    const { id, system_name, button_title, button_subtitle, button_image } = formData;

    setSystemNameDisabled(!!id);

    const disableButtonFields = system_name === 'main_menu';

    setDisableButtonFields(disableButtonFields);
    if (disableButtonFields) {
      if (button_title || button_subtitle || button_image) {
        setValue('button_title', undefined, { shouldDirty: true, shouldTouch: true });
        setValue('button_subtitle', undefined, {
          shouldDirty: true,
          shouldTouch: true,
        });
        setValue('button_image', undefined, { shouldDirty: true, shouldTouch: true });
      }
    }
  }, [setValue, formData, setDisableButtonFields, setSystemNameDisabled]);

  useEffect(() => {
    changeFormSettings();
  }, [changeFormSettings]);

  return null;
};

export default ConditionalFormSettings;
