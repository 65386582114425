import React from 'react';
import { Create } from 'react-admin';

import AcademyPageForm from '../Common/AcademyPageForm';

const AcademyPagesCreate = (props) => (
  <Create {...props}>
    <AcademyPageForm />
  </Create>
);

export default AcademyPagesCreate;
