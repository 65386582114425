const RELATIONAL_FIELDS = {
  academies: {
    academy_pages: 'academy-pages',
  },
  'academy-pages': {
    academy: 'academies',
    page_notes: 'page-notes',
  },
  'case-categories': {
    case_entries: 'case-entries',
  },
  'case-entries': {
    case_categories: 'case-categories',
    plants_list: 'products',
  },
  categories: {
    range: 'ranges',
    products: 'products',
  },
  'page-notes': {
    academy_page: 'academy-pages',
  },
  products: {
    categories: 'categories',
    icons: 'product-icons',
    see_also_products: 'products',
  },
  ranges: {
    categories: 'categories',
  },
  'series-entries': {
    products: 'products',
    case_entries: 'case-entries',
  },
};

const MULTIPART_FIELDS = {
  academies: {
    background: 'single',
    pdf: 'single',
  },
  'academy-pages': {
    media: 'single',
    pdf: 'single',
  },
  'case-categories': {
    background: 'single',
  },
  'case-entries': {
    images: 'multiple',
    service_images: 'multiple',
    testimonials_media: 'multiple',
  },
  categories: {
    background: 'single',
  },
  'menu-pages': {
    background: 'single',
    button_image: 'single',
  },
  products: {
    images: 'multiple',
    care_images: 'multiple',
    service_images: 'multiple',
  },
  'product-icons': {
    image: 'single',
  },
  ranges: {
    background: 'single',
  },
  'series-entries': {
    logo: 'single',
  },
  sustainabilities: {
    images: 'multiple',
  },
};

export { RELATIONAL_FIELDS, MULTIPART_FIELDS };
