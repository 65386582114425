import React from 'react';
import { Edit } from 'react-admin';

import CategoryForm from '../Common/CategoryForm';

const CategoriesEdit = (props) => (
  <Edit {...props} mutationMode='pessimistic'>
    <CategoryForm />
  </Edit>
);

export default CategoriesEdit;
