import React, { useMemo } from 'react';
import { Create } from 'react-admin';

import ProductIconForm from '../Common/ProductIconForm';

const ProductIconsCreate = (props) => {
  const defaultValues = useMemo(() => {
    return {
      type: 'other',
    };
  }, []);

  return (
    <Create {...props}>
      <ProductIconForm defaultValues={defaultValues} />
    </Create>
  );
};

export default ProductIconsCreate;
