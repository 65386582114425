import React from 'react';
import { Datagrid, DateField, List, TextField } from 'react-admin';

import HasSingleRelatedItemField from '../../../common/Fields/HasSingleRelatedItemField';
import HasMultipleRelatedItemField from '../../../common/Fields/HasMultipleRelatedItemField';
import { columnCreatedAtStyle } from '../../../common/styles';

const SeriesEntriesList = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick='edit' sx={columnCreatedAtStyle}>
        <TextField source='title' />
        <HasSingleRelatedItemField label='Logo' sortBy='logo' fieldName='logo' />
        <HasMultipleRelatedItemField label='Products' sortable={false} fieldName={'products'} />
        <HasMultipleRelatedItemField label='Cases' sortable={false} fieldName={'case_entries'} />
        <DateField source='createdAt' />
      </Datagrid>
    </List>
  );
};

export default SeriesEntriesList;
