import React from 'react';
import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';

const KeyValueArrayInput = (props) => {
  const { source, labelPrefix } = props;

  return (
    <ArrayInput source={source}>
      <SimpleFormIterator getItemLabel={(index) => `${labelPrefix} ${index + 1}`}>
        <TextInput source='k' label='' fullWidth />
        <TextInput source='v' label='' fullWidth />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default KeyValueArrayInput;
