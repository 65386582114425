import React from 'react';
import { Datagrid, DateField, List, TextField } from 'react-admin';
import { ColorField } from 'react-admin-color-picker';

import HasMultipleRelatedItemField from '../../../common/Fields/HasMultipleRelatedItemField';
import { columnCreatedAtStyle } from '../../../common/styles';

const SustainabilitiesList = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick='edit' sx={columnCreatedAtStyle}>
        <TextField source='title' />
        <HasMultipleRelatedItemField label='Images' sortBy='images' fieldName='images' />
        <TextField source='header' />
        <TextField source='body' />
        <TextField source='order' />
        <ColorField source='text_color' />
        <ColorField label='Accent color' source='color' />
        <DateField source='createdAt' />
      </Datagrid>
    </List>
  );
};

export default SustainabilitiesList;
