import React, { useMemo } from 'react';
import { Create } from 'react-admin';
import ProductForm from '../Common/ProductForm';

const ProductsCreate = (props) => {
  const defaultValues = useMemo(() => {
    return {
      active: true,
      care_tab: {},
      service_tab: {},
      see_also_tab: {},
    };
  }, []);

  return (
    <Create {...props}>
      <ProductForm defaultValues={defaultValues} />
    </Create>
  );
};

export default ProductsCreate;
