import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'ra-core';

import { sanitizeFieldRestProps } from 'ra-ui-materialui';
/* import {
  PublicFieldProps,
  InjectedFieldProps,
  fieldPropTypes,
} from 'ra-ui-materialui/src/field/types'; */
// import { SxProps } from '@mui/system';

import { getDataFromExistingStrapiImageObject } from './utils';

export const StrapiSingleImageItem = (props) => {
  const { itemData, preferredSize } = props;

  const { alt, src, title } = useMemo(() => {
    if (itemData.id) {
      return getDataFromExistingStrapiImageObject(itemData, preferredSize);
    }

    if (itemData.rawFile) {
      return {
        title: itemData.title,
        alt: itemData.title,
        src: itemData.src,
      };
    }

    return {};
  }, [itemData, preferredSize]);

  return <img title={title} alt={alt || title} src={src} className={ImageFieldClasses.image} />;
};

// No source means, it is embedded in FileInput
export const StrapiImageField = (props) => {
  const {
    className,
    emptyText,
    // source = 'src',
    src,
    title,
    preferredSize = 'thumbnail',
    ...rest
  } = props;
  const record = useRecordContext(props);
  const translate = useTranslate();

  const sourceValue = record;

  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component='span'
        variant='body2'
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText && translate(emptyText, { _: emptyText })}
      </Typography>
    ) : (
      <div className={className} {...sanitizeFieldRestProps(rest)} />
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <Root className={className} {...sanitizeFieldRestProps(rest)}>
        <ul className={ImageFieldClasses.list}>
          {sourceValue.map((file, index) => {
            return (
              <li key={index}>
                <StrapiSingleImageItem itemData={file} preferredSize={preferredSize} />
              </li>
            );
          })}
        </ul>
      </Root>
    );
  }

  return (
    <Root className={className} {...sanitizeFieldRestProps(rest)}>
      <StrapiSingleImageItem itemData={sourceValue} preferredSize={preferredSize} />
    </Root>
  );
};

// What? TypeScript loses the displayName if we don't set it explicitly
StrapiImageField.displayName = 'ImageField';

/* StrapiImageField.propTypes = {
  ...fieldPropTypes,
  src: PropTypes.string,
  title: PropTypes.string,
}; */

const PREFIX = 'RaImageField';

export const ImageFieldClasses = {
  list: `${PREFIX}-list`,
  image: `${PREFIX}-image`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  [`& .${ImageFieldClasses.list}`]: {
    display: 'flex',
    listStyleType: 'none',
  },
  [`& .${ImageFieldClasses.image}`]: {
    margin: '0.25rem',
    width: 200,
    height: 100,
    objectFit: 'contain',
  },
});
