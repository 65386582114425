import React, { useCallback, useMemo, useState } from 'react';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

import LimitedRichTextInput from '../../../common/Inputs/LimitedRichTextInput';
import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';
import { getApiFilterForReferencedInput } from '../../../common/Inputs/utils';

const RangeForm = ({ defaultValues }) => {
  const [formTabACFilter, setFormTabACFilter] = useState({});
  const { categories } = formTabACFilter;

  const categoriesFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(categories, 'title');
  }, [categories]);

  const setCategoriesACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        categories: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  return (
    <SimpleForm defaultValues={defaultValues}>
      <TextInput source='title' validate={required()} fullWidth />
      <LimitedRichTextInput source='header' multiline fullWidth />
      <LimitedRichTextInput source='description' multiline fullWidth />
      <ColorInput source='text_color' validate={required()} />
      <ColorInput source='color' label='Accent color' validate={required()} />
      <TextInput source='order' type='number' validate={required()} fullWidth />
      <ReferenceArrayInput
        source='categories'
        reference='categories'
        fullWidth
        filter={categoriesFilterUsed}
      >
        <AutocompleteArrayInput optionText='title' fullWidth setFilter={setCategoriesACFilter} />
      </ReferenceArrayInput>
      <StrapiImageFileInput
        source='background'
        label='Background'
        placeholder={<p>{'Drop your background image here'}</p>}
      />
    </SimpleForm>
  );
};

export default RangeForm;
