// Using findMode: 'tuple' to get a list with slim data just for showing options
// FYI for more operators:
// https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#filtering
export const getApiFilterForReferencedInput = (
  searchText,
  queriedFieldName,
  queryOperator = '$startsWith'
) => {
  const filter = {
    findMode: 'tuple',
  };

  if (searchText) {
    filter[queriedFieldName] = [queryOperator, searchText];
  }

  return filter;
};
