import React from 'react';
import {
  //BooleanField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import { PostFilter } from '../../../common/Filters';

const UsersList = (props) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick='edit'>
      <TextField source='name' label='User' />
      <ReferenceField
        source='role'
        reference='users-permissions/roles'
        label='Role'
        link={null}
        sortable={false}
      >
        <TextField source='name' />
      </ReferenceField>
      <TextField source='email' />
      {/*<BooleanField source='confirmed' />*/}
      <DateField source='createdAt' />
      <DateField source='updatedAt' />
    </Datagrid>
  </List>
);

export default UsersList;
