import baseStrapiDataProvider, { STRAPI_REQUESTS } from './baseStrapiDataProvider';
import { filterRoles, flattenRelations } from './customizedDataProviderUtils';
import { ERROR_MISSING_HEADER_X_TOTAL_COUNT, httpClient } from './dataProviderUtils';

const dataProviderFunctionNames = [
  'getList',
  'getOne',
  'getMany',
  'getManyReference',
  'update',
  'updateMany',
  'create',
  'delete',
  'deleteMany',
];

const getCustomizations = (apiUrl) => {
  return {
    // strapi users-permissions plugin
    'users-permissions/roles': {
      getList: async (resource, params) => {
        const { headers, json } = await STRAPI_REQUESTS.getList(apiUrl, resource, params);

        if (!headers.has('x-total-count')) {
          throw new Error(ERROR_MISSING_HEADER_X_TOTAL_COUNT);
        }

        return {
          data: filterRoles(json.roles),
          total: parseInt(headers.get('x-total-count').split('/').pop(), 10), // TODO
        };
      },
      getMany: async (resource, params) => {
        const { json } = await STRAPI_REQUESTS.getMany(apiUrl, resource, params);

        return {
          data: json.roles,
        };
      },
    },
    // strapi users-permissions plugin
    users: {
      getList: async (resource, params) => {
        const { headers, json } = await STRAPI_REQUESTS.getList(apiUrl, resource, params);

        if (!headers.has('x-total-count')) {
          throw new Error(ERROR_MISSING_HEADER_X_TOTAL_COUNT);
        }

        return {
          data: flattenRelations(json),
          total: parseInt(headers.get('x-total-count').split('/').pop(), 10), // TODO
        };
      },
      getOne: async (resource, params) => {
        const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`);

        return { data: flattenRelations(json) };
      },
      create: async (resource, params) => {
        const { json } = await httpClient(`${apiUrl}/${resource}`, {
          method: 'POST',
          body: JSON.stringify({ ...params.data }),
        });

        return {
          data: { ...json, role: json.role?.id },
        };
      },
      update: async (resource, params) => {
        const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify({ ...params.data }),
        });

        return {
          data: { ...json, role: json.role?.id },
        };
      },
    },
  };
};

const executeCustomizedFunctionWhenSpecifiedFn = (baseDp, customizations, functionName) => {
  return async (resource, params) => {
    if (customizations?.[resource]?.[functionName]) {
      return customizations[resource][functionName](resource, params);
    }

    return baseDp[functionName](resource, params);
  };
};

const customizedDataProvider = (apiUrl) => {
  const baseDp = baseStrapiDataProvider(apiUrl);
  const customizations = getCustomizations(apiUrl);

  const customizedDataProvider = {};

  for (let functionName of dataProviderFunctionNames) {
    customizedDataProvider[functionName] = executeCustomizedFunctionWhenSpecifiedFn(
      baseDp,
      customizations,
      functionName
    );
  }

  return customizedDataProvider;
};

export default customizedDataProvider;
