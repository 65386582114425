import React from 'react';
import { SimpleForm, TextInput, required } from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

import LimitedRichTextInput from '../../../common/Inputs/LimitedRichTextInput';
import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';

const SustainabilityForm = ({ defaultValues }) => {
  return (
    <SimpleForm defaultValues={defaultValues}>
      <TextInput source='title' validate={required()} fullWidth />
      <TextInput source='header' fullWidth />
      <LimitedRichTextInput source='body' multiline fullWidth />
      <ColorInput source='text_color' validate={required()} />
      <ColorInput source='color' label='Accent color' validate={required()} />
      <TextInput source='order' type='number' validate={required()} fullWidth />
      <StrapiImageFileInput
        source='images'
        label='Images'
        placeholder={<p>{'Drop your images here'}</p>}
        multiple
      />
    </SimpleForm>
  );
};

export default SustainabilityForm;
