import React from 'react';
import { Datagrid, DateField, List, SelectField, TextField } from 'react-admin';

import HasSingleRelatedItemField from '../../../common/Fields/HasSingleRelatedItemField';
import { columnCreatedAtStyle } from '../../../common/styles';
import { TYPE_CHOICES } from '../Common/ProductIconForm';

const RangesList = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick='edit' sx={columnCreatedAtStyle}>
        <TextField source='title' />
        <HasSingleRelatedItemField label='Image' sortBy='image' fieldName='image' />
        <SelectField choices={TYPE_CHOICES} source='type' />
        <DateField source='createdAt' />
      </Datagrid>
    </List>
  );
};

export default RangesList;
