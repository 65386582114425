import React, { Fragment, useCallback, useMemo, useState } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  ReferenceArrayInput,
  TextInput,
  required,
} from 'react-admin';

import KeyValueArrayInput from '../../../common/Inputs/KeyValueArrayInput';
import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';
import { getApiFilterForReferencedInput } from '../../../common/Inputs/utils';

const CaseEntryMainTabContent = () => {
  const [formTabACFilter, setFormTabACFilter] = useState({});
  const { case_categories, series_entries } = formTabACFilter;

  const caseCategoriesFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(case_categories, 'title');
  }, [case_categories]);

  const seriesEntriesFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(series_entries, 'title');
  }, [series_entries]);

  const setCaseCategoriesACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        case_categories: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  const setSeriesEntriesACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        series_entries: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  return (
    <Fragment>
      <TextInput source='name' label='Case Name' validate={required()} fullWidth />
      <BooleanInput source='active' />
      <TextInput source='short_description' fullWidth />
      <TextInput source='art_nr' label='Item Number' validate={required()} fullWidth />
      <ReferenceArrayInput
        reference='series-entries'
        source='series_entries'
        filter={seriesEntriesFilterUsed}
        fullWidth
      >
        <AutocompleteArrayInput
          optionText='title'
          label='Series'
          fullWidth
          setFilter={setSeriesEntriesACFilter}
        />
      </ReferenceArrayInput>
      <StrapiImageFileInput
        source='images'
        label='Images'
        placeholder={<p>{'Drop your images here'}</p>}
        multiple
      />
      <KeyValueArrayInput source='info' labelPrefix='Case Info' />
      <TextInput source='description' multiline={true} fullWidth />
      <ReferenceArrayInput
        reference='case-categories'
        source='case_categories'
        filter={caseCategoriesFilterUsed}
        fullWidth
      >
        <AutocompleteArrayInput
          optionText='title'
          fullWidth
          setFilter={setCaseCategoriesACFilter}
        />
      </ReferenceArrayInput>
    </Fragment>
  );
};

export default CaseEntryMainTabContent;
