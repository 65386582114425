import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'ra-core';

import { sanitizeFieldRestProps } from 'ra-ui-materialui';

import { StrapiSingleFileLinkItem } from './StrapiFileField';
import { StrapiSingleImageItem } from './StrapiImageField';

// StrapiMediaField is a compound field to support different types of content

const StrapiMediaFieldItem = ({
  itemData,
  preferredSize,
  target,
  download,
  ping,
  rel,
  referrerPolicy,
}) => {
  console.log(itemData);

  const isImage = useMemo(() => {
    const { id, rawFile, mime } = itemData;

    return (rawFile && rawFile.type.includes('image/')) || (id && mime.includes('image/'));
  }, [itemData]);

  const isVideo = useMemo(() => {
    const { id, rawFile, mime } = itemData;

    return (rawFile && rawFile.type.includes('video/')) || (id && mime.includes('video/'));
  }, [itemData]);

  if (isImage) {
    return <StrapiSingleImageItem itemData={itemData} preferredSize={preferredSize} />;
  }

  if (isVideo) {
    return (
      <StrapiSingleFileLinkItem
        itemData={itemData}
        target={target}
        download={download}
        ping={ping}
        rel={rel}
        referrerPolicy={referrerPolicy}
      />
    );
  }

  return null;
};

// No source means, it is embedded in FileInput
export const StrapiMediaField = (props) => {
  const {
    className,
    emptyText,
    // source = 'src',
    src,
    title,
    preferredSize = 'thumbnail',
    target,
    download,
    ping,
    rel,
    referrerPolicy,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const translate = useTranslate();

  const sourceValue = record;

  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component='span'
        variant='body2'
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText && translate(emptyText, { _: emptyText })}
      </Typography>
    ) : (
      <div className={className} {...sanitizeFieldRestProps(rest)} />
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <Root className={className} {...sanitizeFieldRestProps(rest)}>
        <ul className={ImageFieldClasses.list}>
          {sourceValue.map((file, index) => (
            <li key={index}>
              <StrapiMediaFieldItem
                itemData={file}
                target={target}
                download={download}
                ping={ping}
                rel={rel}
                referrerPolicy={referrerPolicy}
                preferredSize={preferredSize}
              />
            </li>
          ))}
        </ul>
      </Root>
    );
  }

  return (
    <Root className={className} {...sanitizeFieldRestProps(rest)}>
      <StrapiMediaFieldItem
        itemData={sourceValue}
        target={target}
        download={download}
        ping={ping}
        rel={rel}
        referrerPolicy={referrerPolicy}
        preferredSize={preferredSize}
      />
    </Root>
  );
};

// What? TypeScript loses the displayName if we don't set it explicitly
StrapiMediaField.displayName = 'StrapiMediaField';

const PREFIX = 'RaImageField';

export const ImageFieldClasses = {
  list: `${PREFIX}-list`,
  image: `${PREFIX}-image`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  [`& .${ImageFieldClasses.list}`]: {
    display: 'flex',
    listStyleType: 'none',
  },
  [`& .${ImageFieldClasses.image}`]: {
    margin: '0.25rem',
    width: 200,
    height: 100,
    objectFit: 'contain',
  },
});
