import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { AutocompleteArrayInput, BooleanInput, ReferenceArrayInput, TextInput } from 'react-admin';
import { Grid } from '@mui/material';

import { getApiFilterForReferencedInput } from '../../../common/Inputs/utils';
import HeaderAndBodyArrayInput from '../../../common/Inputs/HeaderAndBodyArrayInput';

const SeeAlsoTabContent = ({ tabDataPropName = 'see_also_tab' }) => {
  const [formTabACFilter, setFormTabACFilter] = useState({});
  const { products } = formTabACFilter;

  const productsFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(products, 'name');
  }, [products]);

  const setProductsACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        products: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  return (
    <Fragment>
      <Grid container columnSpacing={2} justifyContent='space-between' alignItems='center'>
        <Grid item xs={12} md={8}>
          <TextInput source={`${tabDataPropName}.header`} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <BooleanInput source={`${tabDataPropName}.visible`} label='Tab visible' fullWidth />
        </Grid>
      </Grid>
      <ReferenceArrayInput
        reference='products'
        source='see_also_products'
        filter={productsFilterUsed}
        fullWidth
      >
        <AutocompleteArrayInput optionText='name' fullWidth setFilter={setProductsACFilter} />
      </ReferenceArrayInput>
      <HeaderAndBodyArrayInput
        source={`${tabDataPropName}.sections`}
        labelPrefix='See Also Section'
      />
    </Fragment>
  );
};

export default SeeAlsoTabContent;
