import React from 'react';
import { Create } from 'react-admin';

import UserForm from '../Common/UserForm';

const createUserTransform = (data) => {
  return {
    ...data,
    confirmed: true,
  };
};

const UsersCreate = (props) => (
  <Create {...props} transform={createUserTransform}>
    <UserForm isNew />
  </Create>
);

export default UsersCreate;
