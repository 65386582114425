import React, { useMemo } from 'react';
import { Create } from 'react-admin';
import CaseEntryForm from '../Common/CaseEntryForm';

const CaseEntriesCreate = (props) => {
  const defaultValues = useMemo(() => {
    return {
      active: true,
      plants_tab: {},
      service_tab: {},
      testimonials_tab: {},
    };
  }, []);

  return (
    <Create {...props}>
      <CaseEntryForm defaultValues={defaultValues} />
    </Create>
  );
};

export default CaseEntriesCreate;
