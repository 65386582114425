import React, { useMemo } from 'react';
import { Create } from 'react-admin';

import MenuPageForm from '../Common/MenuPageForm';

const MenuPagesCreate = (props) => {
  const defaultValues = useMemo(() => {
    // Without default value, has a display bug
    return {
      text_color: '#ffffff',
    };
  }, []);

  return (
    <Create {...props}>
      <MenuPageForm defaultValues={defaultValues} />
    </Create>
  );
};

export default MenuPagesCreate;
