import React from 'react';
import { Edit } from 'react-admin';

import CaseCategoryForm from '../Common/CaseCategoryForm';

const CaseCategoriesEdit = (props) => {
  return (
    <Edit {...props} mutationMode='pessimistic'>
      <CaseCategoryForm />
    </Edit>
  );
};

export default CaseCategoriesEdit;
