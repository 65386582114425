import { MULTIPART_FIELDS, RELATIONAL_FIELDS } from './baseStrapiDataProviderConfig';

const getStrapiFiltersObject = (resource, filter) => {
  const strapiFilters = {};

  for (let [key, val] of Object.entries(filter || {})) {
    if (key !== 'q') {
      if (RELATIONAL_FIELDS[resource]?.[key]) {
        strapiFilters[key] = [];
        strapiFilters[key]['id'] = [];
        strapiFilters[key]['id']['$eq'] = val;
      } else {
        if (Array.isArray(val)) {
          const [operator, value] = val;
          strapiFilters[key] = [];
          strapiFilters[key][operator] = value;
        } else {
          strapiFilters[key] = [];
          strapiFilters[key]['$eq'] = val;
        }
      }
    }
  }

  return strapiFilters;
};

const unwrapDataValues = (item) => {
  const keys = Object.keys(item);

  for (let key of keys) {
    if (item[key]?.data) {
      let unwrappedNestedData;

      if (Array.isArray(item[key].data)) {
        unwrappedNestedData = unwrapReceivedCollection(item[key].data);
      } else {
        unwrappedNestedData = unwrapReceivedItem(item[key].data);
      }

      item[key] = unwrappedNestedData;
    } else {
      if (item[key]?.data === null) {
        // Omit empty media fields
        delete item[key];
      }
    }
  }
};

const unwrapReceivedItem = (data = {}) => {
  if (data?.attributes) {
    if (Object.keys(data.attributes).length) {
      const unwrappedItem = {
        id: data.id,
        ...data.attributes,
      };
      unwrapDataValues(unwrappedItem);

      return unwrappedItem;
    } else {
      // Compacting relations (necessary for referenceInputs)
      return data.id;
    }
  } else {
    return data;
  }
};

const unwrapReceivedCollection = (collection = []) => {
  return collection.map((item) => unwrapReceivedItem(item));
};

const handleFileUpload = (resource, params) => {
  const paramKeys = Object.keys(params);
  const formData = new FormData();
  const data = {};

  paramKeys.forEach((key) => {
    const multiplicity = MULTIPART_FIELDS[resource]?.[key];

    if (!multiplicity) {
      data[key] = params[key];
    } else {
      if (multiplicity === 'multiple') {
        if (Array.isArray(params[key])) {
          const existingImages = [];

          for (const file of params[key]) {
            if (file.rawFile) {
              formData.append(`files.${key}`, file.rawFile);
            } else if (file.id) {
              existingImages.push(file);
            }
          }

          data[key] = existingImages;
        } else if (params[key]) {
          throw new Error(`Value for ${key} was expected as array, got something else`);
        }
      } else {
        if (params[key]) {
          if (Array.isArray(params[key])) {
            throw new Error(`Value for ${key} was expected as single object, got array`);
          }
          if (params[key].rawFile) {
            formData.append(`files.${key}`, params[key]?.rawFile);
            data[key] = null;
          } else {
            data[key] = params[key];
          }
        } else {
          data[key] = params[key];
        }
      }
    }
  });
  formData.append('data', JSON.stringify(data));

  return formData;
};

export { getStrapiFiltersObject, unwrapReceivedItem, unwrapReceivedCollection, handleFileUpload };
