import React from 'react';
import {
  ArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  SelectInput,
} from 'react-admin';

import CssPercentageInput from '../../../common/Inputs/CssPercentageInput';
import LimitedRichTextInput from '../../../common/Inputs/LimitedRichTextInput';
import StrapiMediaFileInput from '../../../common/Inputs/StrapiMediaFileInput';
import StrapiPdfFileInput from '../../../common/Inputs/StrapiPdfFileInput';

const CHOICES = {
  OPEN_DIRECTION: [
    { id: 'left', name: 'Left' },
    { id: 'right', name: 'Right' },
  ],
};

const AcademyPageForm = () => {
  return (
    <SimpleForm>
      <TextInput source='header' validate={required()} fullWidth />
      <LimitedRichTextInput source='description' fullWidth />
      <LimitedRichTextInput source='body' multiline fullWidth />
      <StrapiMediaFileInput
        source='media'
        label='Media'
        placeholder={<p>{'Drop your image or video here'}</p>}
      />
      <StrapiPdfFileInput source='pdf' label='Pdf' />
      <ArrayInput source='page_notes'>
        <SimpleFormIterator getItemLabel={(index) => `Note ${index + 1}`}>
          <TextInput source='text' validate={required()} fullWidth />
          <CssPercentageInput
            source='pos_x'
            label='Horizontal (X) Position (in %)'
            defaultValue={'50'}
            validate={required()}
            fullWidth
          />
          <CssPercentageInput
            source='pos_y'
            label='Vertical (Y) Position (in %)'
            defaultValue={'50'}
            validate={required()}
            fullWidth
          />
          <SelectInput
            source='open_direction'
            label='Open Direction'
            validate={required()}
            choices={CHOICES.OPEN_DIRECTION}
            fullWidth
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export default AcademyPageForm;
