import React, { useMemo } from 'react';
import { FileInput } from 'react-admin';

import { FILE_FORMATS_ACCEPTED } from '../../../utils/constants';
import { StrapiMediaField } from '../../common/Fields/StrapiMediaField';
import { fileInputStyle, imageFieldStyle } from '../../common/styles';

// FYI: https://react-dropzone.netlify.app/#proptypes

const StrapiMediaFileInput = ({
  source,
  label,
  placeholder,
  multiple = false,
  disabled,
  ...restProps
}) => {
  const placeholderUsed = useMemo(() => {
    return (
      placeholder ||
      (multiple ? (
        <p>{'Drop your images or videos here'}</p>
      ) : (
        <p>{'Drop your image or video here'}</p>
      ))
    );
  }, [placeholder, multiple]);

  const fileInputOptions = useMemo(() => {
    return { disabled };
  }, [disabled]);

  return (
    <FileInput
      source={source}
      label={label}
      accept={[...FILE_FORMATS_ACCEPTED.IMAGES, FILE_FORMATS_ACCEPTED.VIDEOS_ANY]}
      placeholder={placeholderUsed}
      sx={fileInputStyle}
      multiple={multiple}
      options={fileInputOptions}
      {...restProps}
    >
      <StrapiMediaField
        source='src'
        title='title'
        target='_blank'
        referrerPolicy='no-referrer'
        sx={imageFieldStyle}
      />
    </FileInput>
  );
};

export default StrapiMediaFileInput;

//
