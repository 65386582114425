import React from 'react';
import { Edit } from 'react-admin';

import MenuPageForm from '../Common/MenuPageForm';

const MenuPagesEdit = (props) => {
  return (
    <Edit {...props} mutationMode='pessimistic'>
      <MenuPageForm />
    </Edit>
  );
};

export default MenuPagesEdit;
