import React, { useMemo } from 'react';
import { FileInput } from 'react-admin';

import { FILE_FORMATS_ACCEPTED } from '../../../utils/constants';
import { StrapiImageField } from '../../common/Fields/StrapiImageField';
import { fileInputStyle, imageFieldStyle } from '../../common/styles';

// FYI: https://react-dropzone.netlify.app/#proptypes

const StrapiImageFileInput = ({
  source,
  label,
  placeholder,
  multiple = false,
  disabled,
  ...restProps
}) => {
  const placeholderUsed = useMemo(() => {
    return (
      placeholder || (multiple ? <p>{'Drop your images here'}</p> : <p>{'Drop your image here'}</p>)
    );
  }, [placeholder, multiple]);

  const fileInputOptions = useMemo(() => {
    return { disabled };
  }, [disabled]);

  return (
    <FileInput
      source={source}
      label={label}
      accept={FILE_FORMATS_ACCEPTED.IMAGES}
      placeholder={placeholderUsed}
      sx={fileInputStyle}
      multiple={multiple}
      options={fileInputOptions}
      {...restProps}
    >
      <StrapiImageField source='src' title='title' sx={imageFieldStyle} />
    </FileInput>
  );
};

export default StrapiImageFileInput;
