import React, { useMemo } from 'react';
import { Create } from 'react-admin';

import CaseCategoryForm from '../Common/CaseCategoryForm';

const CaseCategoriesCreate = (props) => {
  const defaultValues = useMemo(() => {
    // Without default value, has a display bug
    return {
      color: '#000000',
    };
  }, []);

  return (
    <Create {...props}>
      <CaseCategoryForm defaultValues={defaultValues} />
    </Create>
  );
};

export default CaseCategoriesCreate;
