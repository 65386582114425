import React, { Fragment } from 'react';
import { BooleanInput, TextInput } from 'react-admin';
import { Grid } from '@mui/material';

import HeaderAndBodyArrayInput from '../../../common/Inputs/HeaderAndBodyArrayInput';
import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';

const CareTabContent = ({ tabDataPropName = 'care_tab' }) => {
  return (
    <Fragment>
      <Grid container columnSpacing={2} justifyContent='space-between' alignItems='center'>
        <Grid item xs={12} md={8}>
          <TextInput source={`${tabDataPropName}.header`} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <BooleanInput source={`${tabDataPropName}.visible`} label='Tab visible' fullWidth />
        </Grid>
      </Grid>
      <StrapiImageFileInput
        source='care_images'
        label='Care Images'
        placeholder={<p>{'Drop your images here'}</p>}
        multiple
      />
      <HeaderAndBodyArrayInput source={`${tabDataPropName}.sections`} labelPrefix='Care Section' />
    </Fragment>
  );
};

export default CareTabContent;
