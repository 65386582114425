import React, { useCallback, useMemo, useState } from 'react';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';

import LimitedRichTextInput from '../../../common/Inputs/LimitedRichTextInput';
import StrapiImageFileInput from '../../../common/Inputs/StrapiImageFileInput';
import { getApiFilterForReferencedInput } from '../../../common/Inputs/utils';

const CaseCategoryForm = ({ defaultValues }) => {
  const [formTabACFilter, setFormTabACFilter] = useState({});
  const { case_entries } = formTabACFilter;

  const caseEntriesFilterUsed = useMemo(() => {
    return getApiFilterForReferencedInput(case_entries, 'name');
  }, [case_entries]);

  const setCaseEntriesACFilter = useCallback(
    (searchText) => {
      setFormTabACFilter((prevFilter) => ({
        ...prevFilter,
        case_entries: searchText,
      }));
    },
    [setFormTabACFilter]
  );

  return (
    <SimpleForm defaultValues={defaultValues}>
      <TextInput source='title' validate={required()} fullWidth />
      <LimitedRichTextInput source='header' multiline fullWidth />
      <LimitedRichTextInput source='description' multiline fullWidth />
      <TextInput source='order' type='number' validate={required()} fullWidth />
      <ColorInput source='color' label='Accent color' validate={required()} />
      <ReferenceArrayInput
        reference='case-entries'
        source='case_entries'
        filter={caseEntriesFilterUsed}
        fullWidth
      >
        <AutocompleteArrayInput
          label='Cases'
          optionText='name'
          fullWidth
          setFilter={setCaseEntriesACFilter}
        />
      </ReferenceArrayInput>
      <StrapiImageFileInput
        source='background'
        label='Background'
        placeholder={<p>{'Drop your background image here'}</p>}
      />
    </SimpleForm>
  );
};

export default CaseCategoryForm;
