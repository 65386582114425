import React, { useMemo } from 'react';
import { Create } from 'react-admin';

import RangeForm from '../Common/RangeForm';

const RangesCreate = (props) => {
  const defaultValues = useMemo(() => {
    // Without default value, has a display bug
    return {
      color: '#000000',
      text_color: '#ffffff',
    };
  }, []);

  return (
    <Create {...props}>
      <RangeForm defaultValues={defaultValues} />
    </Create>
  );
};

export default RangesCreate;
