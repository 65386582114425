import React, { useMemo } from 'react';
import { Edit } from 'react-admin';

import ProductIconForm from '../Common/ProductIconForm';

const ProductIconsEdit = (props) => {
  const defaultValues = useMemo(() => {
    return {
      type: 'other',
    };
  }, []);

  return (
    <Edit {...props} mutationMode='pessimistic'>
      <ProductIconForm defaultValues={defaultValues} />
    </Edit>
  );
};

export default ProductIconsEdit;
