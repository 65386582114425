import React from 'react';
import { FormTab, TabbedForm } from 'react-admin';

import ProductMainTabContent from './ProductMainTabContent';
import CareTabContent from './CareTabContent';
import ServiceTabContent from './ServiceTabContent';
import SeeAlsoTabContent from './SeeAlsoTabContent';

const ProductForm = ({ defaultValues }) => {
  return (
    <TabbedForm defaultValues={defaultValues}>
      <FormTab label='Product'>
        <ProductMainTabContent />
      </FormTab>
      <FormTab label='Care' path='care'>
        <CareTabContent />
      </FormTab>
      <FormTab label='Service' path='service'>
        <ServiceTabContent />
      </FormTab>
      <FormTab label='See Also' path='see-also'>
        <SeeAlsoTabContent />
      </FormTab>
    </TabbedForm>
  );
};

export default ProductForm;
