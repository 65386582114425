import React, { useMemo } from 'react';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  ExportButton,
  List,
  ReferenceInput,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import Slideshow from '@mui/icons-material/Slideshow';

import HasSingleRelatedItemField from '../../../common/Fields/HasSingleRelatedItemField';
import HasMultipleRelatedItemField from '../../../common/Fields/HasMultipleRelatedItemField';
import { columnCreatedAtStyle } from '../../../common/styles';

const EditRelatedAcademyButton = ({ academy }) => {
  const academyEdit = useMemo(
    () => ({
      pathname: `/academies/${academy}`,
    }),
    [academy]
  );

  return (
    <Button
      startIcon={<Slideshow />}
      component={Link}
      to={academyEdit}
      size='small'
      sx={{ lineHeight: 1.5 }}
    >
      {'Edit Academy'}
    </Button>
  );
};

// Hiding Filter button (no reason to add or interact with it)
const ListActions = () => {
  const {
    filterValues: { academy },
  } = useListContext();

  return (
    <TopToolbar>
      {academy ? <EditRelatedAcademyButton academy={academy} /> : null}
      <ExportButton />
    </TopToolbar>
  );
};

const AcademyPagesList = (props) => {
  const filterInputs = useMemo(() => {
    return [
      <ReferenceInput source='academy' reference='academies' allowEmpty alwaysOn>
        <AutocompleteInput optionText='title' />
      </ReferenceInput>,
    ];
  }, []);

  return (
    <List actions={<ListActions />} filters={filterInputs} {...props}>
      <Datagrid rowClick='edit' sx={columnCreatedAtStyle}>
        <TextField source='header' label='Header' fullWidth />
        <TextField source='order' label='Order' fullWidth />
        <HasSingleRelatedItemField label='Media' sortBy='media' fieldName='media' />
        <HasSingleRelatedItemField label='Pdf' sortBy='pdf' fieldName='pdf' />
        <HasMultipleRelatedItemField
          label='Notes (# of)'
          sortBy='page_notes'
          fieldName='page_notes'
        />
        <DateField source='createdAt' />
      </Datagrid>
    </List>
  );
};

export default AcademyPagesList;
