import React from 'react';
import { Edit } from 'react-admin';

import UserForm from '../Common/UserForm';

const editUserTransform = (data) => {
  if (!data.password || !data.password.length) {
    delete data.password;
  }

  return data;
};

const UsersEdit = (props) => (
  <Edit {...props} transform={editUserTransform} mutationMode='pessimistic'>
    <UserForm />
  </Edit>
);

export default UsersEdit;
