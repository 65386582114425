import React from 'react';
import { Datagrid, DateField, List, TextField } from 'react-admin';
import { ColorField } from 'react-admin-color-picker';

import HasSingleRelatedItemField from '../../../common/Fields/HasSingleRelatedItemField';
import { columnCreatedAtStyle } from '../../../common/styles';

const MenuPagesList = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick='edit' sx={columnCreatedAtStyle}>
        <TextField source='button_title' />
        <TextField source='button_subtitle' />
        <TextField source='header' />
        <TextField source='body' />
        <ColorField source='text_color' />
        <HasSingleRelatedItemField
          label='Button Image'
          sortBy='button_image'
          fieldName='button_image'
        />
        <HasSingleRelatedItemField label='Background' sortBy='background' fieldName='background' />
        <TextField source='system_name' />
        <DateField source='createdAt' />
      </Datagrid>
    </List>
  );
};

export default MenuPagesList;
