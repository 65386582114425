import React from 'react';
import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  ClearButtons,
} from 'ra-input-rich-text';

const LimitedRichTextInput = ({ size, ...props }) => {
  return (
    <RichTextInput
      toolbar={
        <RichTextInputToolbar>
          <FormatButtons size={size} />
          <ClearButtons size={size} />
        </RichTextInputToolbar>
      }
      {...props}
    />
  );
};

export default LimitedRichTextInput;
