import React, { useMemo } from 'react';
import { FileInput } from 'react-admin';

import { FILE_FORMATS_ACCEPTED } from '../../../utils/constants';
import { StrapiFileField } from '../../common/Fields/StrapiFileField';
import { fileInputStyle } from '../../common/styles';

const StrapiPdfFileInput = ({
  source,
  label,
  placeholder,
  multiple = false,
  disabled,
  ...restProps
}) => {
  const placeholderUsed = useMemo(() => {
    return (
      placeholder ||
      (multiple ? <p>{'Drop your pdf files here'}</p> : <p>{'Drop your pdf file here'}</p>)
    );
  }, [placeholder, multiple]);

  const fileInputOptions = useMemo(() => {
    return { disabled };
  }, [disabled]);

  return (
    <FileInput
      source={source}
      label={label}
      accept={FILE_FORMATS_ACCEPTED.PDF}
      placeholder={placeholderUsed}
      sx={fileInputStyle}
      multiple={multiple}
      options={fileInputOptions}
      {...restProps}
    >
      <StrapiFileField source='src' title='title' target='_blank' referrerPolicy='no-referrer' />
    </FileInput>
  );
};

export default StrapiPdfFileInput;
