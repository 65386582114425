import React, { Fragment, useMemo } from 'react';
import { Datagrid, DateField, EditButton, FunctionField, List, TextField } from 'react-admin';
import { Link } from 'react-router-dom';
import { ColorField } from 'react-admin-color-picker';
import { Button } from '@mui/material';
import Article from '@mui/icons-material/Article';

import HasSingleRelatedItemField from '../../../common/Fields/HasSingleRelatedItemField';
// import HasMultipleRelatedItemField from '../../../common/Fields/HasMultipleRelatedItemField';

const datagridSxStyle = {
  '& .column-actions': {
    textAlign: 'right',
  },
  '& .column-createdAt': {
    textAlign: 'right',
  },
};

const AcademyRowActions = ({ record }) => {
  const academyPagesLink = useMemo(
    () => ({
      pathname: '/academy-pages',
      search: `filter=${JSON.stringify({ academy: record.id })}`,
    }),
    [record]
  );

  return (
    <Fragment>
      <EditButton />
      <Button startIcon={<Article />} component={Link} size='small' to={academyPagesLink}>
        {'View pages'}
      </Button>
    </Fragment>
  );
};

const AcademiesList = (props) => {
  return (
    <List {...props}>
      <Datagrid sx={datagridSxStyle}>
        <TextField source='title' fullWidth />
        <HasSingleRelatedItemField label='Background' sortBy='background' fieldName='background' />
        <HasSingleRelatedItemField label='Pdf' sortBy='pdf' fieldName='pdf' />
        <TextField source='header' fullWidth />
        <TextField source='description' fullWidth />
        <TextField source='order' type='number' fullWidth />
        <ColorField source='text_color' />
        <ColorField label='Accent color' source='color' />
        <FunctionField
          source='actions'
          sortable={false}
          render={(record) => <AcademyRowActions record={record} />}
        />
        <DateField source='createdAt' />
      </Datagrid>
    </List>
  );
};

export default AcademiesList;
