import React from 'react';
import { Edit } from 'react-admin';

import AcademyPageForm from '../Common/AcademyPageForm';

const editAcademyPagesTransform = (data) => {
  if (data.page_notes) {
    for (let pageNote of data.page_notes) {
      delete pageNote.createdAt;
      delete pageNote.updatedAt;
    }
  }

  return data;
};

const AcademyPagesEdit = (props) => (
  <Edit {...props} mutationMode='pessimistic' transform={editAcademyPagesTransform}>
    <AcademyPageForm />
  </Edit>
);

export default AcademyPagesEdit;
